import React from 'react';
import FilterCompany from "../../components/Company/FilterCompany"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BannerPage from "../../components/Common/BannerPage"

const CompList = ({location, uri}) => (
  <>
    <BannerPage />
    <Layout page="page">
      <SEO title="Company" />
      <div style={{ maxWidth: `1260px`, position:"relative", marginBottom: `1.45rem` }}>
          <FilterCompany uri={uri} location={location} />
      </div>
    </Layout>
  </>
)

export default CompList


