import { rest, restForm } from "../utils/rest"

const get = (endpoint, args={}) => rest().get(endpoint, {params:args}) 
const post = endpoint => rest().post(endpoint);
const postForm = endpoint => restForm().post(endpoint);

export const getCompanies = (args) => {
    return new Promise((resolve, reject) => {
        const api = get('company', args).then(res=>{
            resolve(res)
        }).catch(err=>{
            reject(err)
        })
    })
}

export const getCompanyDetail = (args) => {
    return new Promise((resolve, reject) => {
        const api = get('company/detail', args).then(res=>{
            resolve(res)
        }).catch(err=>{
            reject(err)
        })
    })
}