
import React from "react"
import { kebabCase } from "lodash"
import { Link } from "gatsby"
import { FaBuilding } from "react-icons/fa";
import { displayHumanDate } from "../../utils/helpers"
import { FaMapMarker, FaHourglassHalf } from "react-icons/fa"

const CompanyHorizontalGridLayout = ({ items, title='Our Company' }) => {

    return(<div style={{ margin: '2rem auto' }}>
       
      <ul className="row comp-list justify-content-center" style={{ padding: 0 }}>
        {items.map(item => {
            let img
            if( item.employers_logo ) {
                img = <img src={item.employers_logo} style={{maxWidth:90, maxHeight:220}} />
            } else {
                img = <FaBuilding size={90} color="#ccc" />
            }

            return (
            <li
                key={item.employers_id}
                className={"col-lg-6 col-12 mb-5"}
                style={{
                    float:"left",
                    listStyle: 'none',
                }}
            >
                <div className="shadow card mb-3">
                    <div className="row no-gutters">
                        <div className="img-wrapper col-4 col-lg-4 d-flex border-right align-items-center justify-content-center">
                            {img}
                        </div>
                        <div className="col-8 col-lg-8">
                            <div className="card-body" style={{padding:'1rem'}}>
                                <h4 className="card-title mb-2" style={{fontSize:'1.1rem'}}>{item.employers_company_name}</h4>
                                {item.company_type && <h4><span className="badge badge-pill badge-light" style={{fontSize:`0.85rem`, background:'#ddd'}}>{item.company_type.company_type}</span></h4>}
                                

                                {item.businness_type && <p className="mb-2"><small className="text-muted" style={{fontSize:'0.8em'}}>{item.businness_type.business_type}</small></p>}
                            </div>
                            <div className="card-footer" style={{position:"absolute", bottom:0, width:"100%"}}>
                                <div className="row">
                                    <div className="col left">
                                       <span className="text-muted" style={{fontSize:12}}>
                                            <FaMapMarker size={12} /> {item.province.lokasi_nama}
                                        </span>
                                    </div>
                                    <div className="col right text-right">
                                        <Link style={{fontSize:12}} className="btn btn-sm btn-danger" to={`/company/detail/${item.employers_id}_${kebabCase(item.employers_company_name)}`} state={{ id: item.employers_id }}>
                                            Detail
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </li>)
        })}
      </ul>
    </div>)
}
  

export default CompanyHorizontalGridLayout