import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const BannerPage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "banner-page.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, maxHeight:667, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (<div style={{
        position:"absolute",
        margin:"0 auto",
        maxHeight:520,
        width:"100%",
        background:'#000', 
      }}>
      <Img imgStyle={{ opacity:0.5, maxHeight:520 }} fluid={data.placeholderImage.childImageSharp.fluid} />
  </div>)
}

export default BannerPage
