import React, { Component } from 'react';
import { navigate  } from 'gatsby'
import GridLayout from "../Company/GridLayout"
import { getCompanies } from '../../actions/company'
import ReactPaginate from 'react-paginate';
import { merge } from "lodash"
import ContentLoaderJobGrid from "../Loader/ContentLoaderJobGrid"
import HeadingList from "../Common/HeadingList"
import { FaSearch } from "react-icons/fa"
import { getAutoCompletionProv } from "../../actions/jobs"
import Autocomplete from "react-autocomplete"

class FilterCompany extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            args:{
                location:'',
                keyword:'',
                offset:0,
                jumPage:15,
            },
            cities:[],
            city:'',
            page:1,
            forcePageOpen:false,
            data:null,
            totalRecords:0,
            loading:false,
            onFilter:false,
        }
    }

    componentDidMount() {

        const urlParams = new URLSearchParams(this.props.location.search);
        let page = 1
        let offset = 0
        if ( urlParams.get('page') ) {
            page = urlParams.get('page') ? urlParams.get('page') : 0;
            if( page > 0 ) {
                offset = Math.ceil( (page-1) * this.state.args.jumPage);
            } 

            this.setState({
                forcePageOpen:true,
            })
        }   

        this.setState({
            loading:true,
            onFilter:true,
            page:page
        })

        this.onFetch({
            offset:offset
        })

    }

    onFetch(args={}) {
        const params = merge(this.state.args, args)
        this.setState({args:params}, ()=> {
            getCompanies(params).then(res=>{
                const { data } = res
                this.setState({
                    loading:false,
                    data:data[0].aData,
                    totalRecords:data[0].iTotalRecords
                })
            }).catch(err=>{
                this.setState({
                    loading:false,
                })
            })
        })
    }

    onRenderCity(e) {
        let params = {city:e.target.value}
        this.setState(params)

        if( e.target.value == '' ) {
            this.setState({
                args:{
                    ...this.state.args,
                    location:''
                }
            })
        }

        if( e.target.value.length > 0 ) {
            getAutoCompletionProv(e.target.value)
                .then(res=>{
                    this.setState({ cities: res })
                })
        }
        
    }

    onPageClick(data) {
        let selected = data.selected;
        let offset = Math.ceil(selected * this.state.args.jumPage);

        navigate(`/company/?page=${selected+1}`, {
            state: { location: this.state.args.location, keyword: this.state.args.keyword },
        })

        this.setState({
            loading:true,
            onFilter:true,
            forcePageOpen:false,
            page:selected+1
        })
        this.onFetch({
            offset:offset
        })
    }

    onChange(e) {
        this.setState({
            args:{
                ...this.state.args,
                [e.target.name]:e.target.value
            }
        })
    }

    onClick() {
        this.setState({
            loading:true,
            onFilter:true,
            page:1,
            args:{
                ...this.state.args,
                offset:0
            }
        })
        this.onFetch({
            keyword:this.state.args.keyword,
            location:this.state.args.location,
        })
    }

    render() {
        let data = this.state.data
        const city = this.state.city ? ' in ' + this.state.city : ''
        return (
            <div className="filterBox">
                <div className="wrapperBoxFilter">
                    <form className="form-inline" style={{
                        justifyContent:"center",
                        padding:'10px 0px'
                    }}>
                        <div className="input-group">
                            <input type="text" name="keyword" value={this.state.args.keyword} onChange={this.onChange.bind(this)} className="job-input form-control form-control-lg" placeholder="Company name" id="" />

                            <Autocomplete
                                inputProps={{
                                    type:"text",
                                    placeholder:"Location",
                                    style:{
                                        borderRadius:0
                                    },
                                    className:"form-control form-control-lg" 
                                }}
                                wrapperProps={{
                                    className:"autocompletion"
                                }}
                                getItemValue={(item) => item.name}
                                items={this.state.cities}
                                renderMenu={children => (
                                    <div className="menu">
                                    {children}
                                    </div>
                                )}
                                renderItem={(item, isHighlighted) =>
                                    <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                                        {item.name}
                                    </div>
                                }
                                value={this.state.city}
                                onChange={this.onRenderCity.bind(this)}
                                onSelect={(value, item) => {
                                    // set the menu to only the selected item
                                    this.setState({ city:value, args: {
                                        ...this.state.args,
                                        location:item.code
                                    } })
                                }}
                                />

                            <div className="input-group-append" style={{height:48}}>
                                <button className="btn btn-danger px-4" type="button" onClick={this.onClick.bind(this)} id="button-addon2">
                                    <FaSearch size={24} />
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                {this.state.loading && <ContentLoaderJobGrid count={15} />}
                {!this.state.loading && data && 
                    <>
                        <HeadingList 
                            title={this.state.args.keyword != '' || this.state.args.location != '' ? 'Search Company for \"' + this.state.args.keyword + '\"' + city : 'All Company' } 
                            page={this.state.page}
                            totalRecords={this.state.totalRecords}
                            totalPages={Math.ceil(this.state.totalRecords / this.state.args.jumPage)} />

                        <GridLayout height={400} 
                            items={data} />

                        {this.state.totalRecords > this.state.args.jumPage && <nav className="Page navigation d-flex justify-content-center align-items-center">
                            <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={Math.ceil(this.state.totalRecords / this.state.args.jumPage)}
                                marginPagesDisplayed={2}
                                forcePage={parseInt(this.state.page)-1}
                                pageRangeDisplayed={3}
                                onPageChange={this.onPageClick.bind(this)}
                                containerClassName={'pagination'}
                                subContainerClassName={'pagination'}
                                activeClassName={'active'}
                                pageClassName={'page-item'}
                                pageLinkClassName={'page-link'}
                                disabledClassName={'disabled'}
                                previousClassName={'page-item'}
                                previousLinkClassName={'page-link'}
                                nextClassName={'page-item'}
                                nextLinkClassName={'page-link'}
                                breakClassName={'page-item disabled'}
                                breakLinkClassName={'page-link'}
                            />
                        </nav>}
                        
                    </>
                }
                
            </div>
        )
    }
}

FilterCompany.defaultProps = {
    items:null
}


export default FilterCompany